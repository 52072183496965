<template>
  <div>
    <div class="type-headline-base text-brand-dark mb-12">
      {{ $lang('checkoutResources', 'simpleFormHeading') }}
    </div>
    <div class="bg-critical-primary p-16 text-white type-headline-xs mb-16 flex">
      <img src="/zynicons/warning-hexagon.svg" class="h-20 mr-12">
      {{ $lang('checkoutResources', 'formDisclaimer') }}
    </div>
    <div>
      <div class="border-b border-neutral-light">
        <div
          class="form-row md:flex-row flex-col border-neutral-light"
        >
          <div class="w-full md:w-3/5 md:border-r border-neutral-light">
            <TextInput
              :id="'sePersonEmail'"
              :placeholder="$lang('checkoutResources', 'formEmail')"
              :type="'email'"
              :form-input="true"
              :error="emailError"
              :warning="emailWarning"
              :delay-submit="true"
              :init-value="userLoading ? '' : email"
              :autocomplete="'email'"
              @submit-input="emailInput"
            />
          </div>
          <div class="w-full md:w-2/5 border-neutral-light md:border-t-0 border-t">
            <TextInput
              :id="'swePersonPostalCode'"
              :placeholder="$lang('checkoutResources', 'formPostalCode')"
              :type="'zip'"
              :form-input="true"
              :error="postalCodeError"
              :delay-submit="true"
              :init-value="userLoading ? '' : postalCode"
              :input-mode="'numeric'"
              :autocomplete="'postal-code'"
              @submit-input="postalCodeInput"
            />
          </div>
        </div>
        <div
          v-if="
            shouldShowMetaBox &&
              (shouldShowNewsletterBox || (!hasUserAccount && email))
          "
          class="form-row border-neutral-light"
        >
          <div class="flex-1 pt-20 pb-4 pr-16 pl-16">
            <Checkbox
              v-if="!hasUserAccount && !isSignedIn"
              :checked="wantToCreateAccount"
              :text="$lang('checkoutResources', 'accountCreationCheckbox')"
              @on-check="toggleCreateAccount"
            />
            <div
              v-if="
                !hasUserAccount && !isSignedIn && createAccountUsps.length > 0
              "
              class="p-16 mb-16 bg-neutral-lighter"
            >
              <div
                v-for="usp in createAccountUsps"
                :key="`createAccountUsp${usp}`"
                class="flex items-center type-xs mb-12"
              >
                <img src="/zynicons/check-primary.svg" class="h-16 mr-12">
                {{ usp }}
              </div>
              <WysiwygWrapper
                :style-config="{ 'mt-8 type-xxs wysiwyg': true }"
                :html="privacyPolicy"
                @open-policy-modal="onOpenPrivacyPolicyModal"
              />
            </div>
            <Modal
              v-if="privacyPolicyModalIsOpen"
              class="!z-modal"
              :wide="true"
              @close-modal="onClosePrivacyPolicyModal"
            >
              <div>
                <BlocksContentArea
                  v-for="(block, index) in privacyPolicyBlocks"
                  :key="'PrivacyPolicy-' + block?.ContentLink?.Expanded?.__typename + '-' + index"
                  :block="block?.ContentLink?.Expanded"
                />
              </div>
            </Modal>
            <Checkbox
              v-if="shouldShowNewsletterBox && !subscribedToNewsletter"
              :checked="subscribeToNewsLetter"
              :text="$lang('checkoutResources', 'newsletterSignupText')"
              @on-check="toggleSubscribeToNewsLetter"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-24">
      <Button
        :is-block="true"
        :size="Sizes.lg"
        :theme="Themes.primary"
        :text="$lang('checkoutResources', 'continueToDelivery')"
        :loading="loading"
        @click="onSetBuyer"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import emailMisspelled, { all } from 'email-misspelled';
import _debounce from 'lodash/debounce';
import { Sizes, Themes } from '../constants/button-layouts';
import TextInput from './form-elements/TextInput.vue';
import Checkbox from './form-elements/Checkbox.vue';
import Button from './globals/Button.vue';
import Modal from '~/components/modals/Modal.vue';
import { type IUser, PurchaseType, type IAccountStatusResult } from '~/models/api-types';
import type { CheckoutPageFragment } from '#gql';
import { useCheckoutStore } from '~/store/checkout';
import { useUserStore } from '~/store/user';
import { useVoyadoStore } from '~/store/voyado';
import * as Sentry from '@sentry/vue';

const { $event } = useNuxtApp();
const runTimeConfig = useRuntimeConfig();
const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();
const userStore = useUserStore();
const voyadoStore = useVoyadoStore();
const privacyPolicyModalIsOpen = ref(false);

const { apiGet, apiPut, apiPost, lastError } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  createAccountUsps: string[],
  isMobile?: boolean,
  purchaseType: string,
  isSample: boolean,
  isSignedIn: boolean,
  privacyPolicy: string,
  page: CheckoutPageFragment,
}>();

const user: Ref<IUser | undefined> = ref();

const hasUserAccount = ref(true);
const wantToCreateAccount = ref(false);
const wantToSubscribeToNewsletter = ref(false);
const subscribeToNewsLetter = ref(false);

const email = ref('');
const postalCode = ref('');
const postalCodeError = ref('');
const emailError = ref('');
const emailWarning = ref('');
let checkAccountDebounced = (): void => {};
const userLoading = ref(false);
const subscribedToNewsletter = ref(false);
const loading = ref(false);

const shouldShowNewsletterBox = computed(() => {
  return !!config.currentMarket?.enableNewsletter;
});

const shouldShowMetaBox = computed(() => {
  return (
    (!hasUserAccount.value && !props.isSignedIn) ||
    !subscribedToNewsletter.value
  );
});

const cartItems = computed(() => {
  if (props.purchaseType === PurchaseType.Subscription) {
    return cartStore.subscriptionItems;
  } else if (props.purchaseType === PurchaseType.Sampling) {
    return cartStore.samplingItems;
  } else {
    return cartStore.items;
  }
});

const privacyPolicyBlocks = computed(() => {
  return (
    props.page.PrivacyPolicyModalBlocks?.filter(
      (block) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});

const emit = defineEmits<{
  (event: 'set-newsletter-status', value: boolean): void,
  (event: 'set-create-account-status', value: boolean): void,
  (event: 'on-confirmed', value: string): void,
  (event: 'set-zip', value: string): void,
  (event: 'hide-checkboxes'): void,
}>();

watch(
  () => props.isSignedIn,
  () => {
    fetchUser();
  }
);

watch(
  () => email.value,
  () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value.trim())) {
      checkForNewsletter();
    }
  }
);

onMounted(async() => {
  checkAccountDebounced = _debounce(checkAccount, 300);
  await fetchUser();
});

const checkForNewsletter = async() => {
  const { currentMarket, language } = config;

  const voyadoData = await apiPost(
    `voyado/newsletter/status?language=${language}&countryCode=${currentMarket?.countryCode}`,
    {
      email: email.value,
    }
  );

  subscribedToNewsletter.value = voyadoData?.hasSubscribedToNewsletter;
};

const fetchUser = async() => {
  if (props.isSignedIn) {
    const { currentMarket, language } = config;
    userLoading.value = true;
    const res = await apiGet(
      `user/account?language=${language}&countryCode=${currentMarket?.countryCode}`
    );
    if (res) {
      user.value = res;
      email.value = user.value?.email || '';
      postalCode.value = user.value?.invoiceAddress?.postalCode || '';
    }
    await fetchBuyer();
    userLoading.value = false;
  } else {
    await fetchBuyer();
  }
};

const fetchBuyer = async() => {
  const { currentMarket, language } = config;

  const res = await apiGet(
    `checkout/buyer?language=${language}&countryCode=${currentMarket?.countryCode}&purchaseType=${props.purchaseType}`
  );
  if (res && res.email) email.value = res.email;
  if (res && res.postalCode) postalCode.value = res.postalCode;
  emit('set-zip', postalCode.value);

  userLoading.value = false;
};

const postalCodeInput = async(input: string) => {
  postalCode.value = input;
  postalCodeError.value = '';
  if (input) {
    const { currentMarket } = config;
    const res = await apiPost(
      'checkout/postalcodes/validation',
      {
        postalCode: input,
        countryCode: currentMarket?.countryCode,
      }
    );
    if (res && !res.isValid) {
      postalCodeError.value = $lang(
        'validationErrorResources',
        'invalidPostalCode'
      );
    }
  }
};

const emailInput = (input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    if (!props.isSample) {
      checkAccountDebounced();
    }
    const emailChecker = emailMisspelled({ domains: all });

    if (emailChecker(input).length > 0) {
      let warningSting = $lang(
        'validationErrorResources',
        'emailWarning'
      );

      warningSting = warningSting.replace(
        '{emailDomain}',
        emailChecker(input)[0].suggest
      );
      emailWarning.value = warningSting;
    } else {
      emailWarning.value = '';
    }
    emailError.value = '';
  } else if (!input) {
    emailWarning.value = '';
    emailError.value = '';
  } else {
    emailWarning.value = '';
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
  }
};

const checkAccount = async() => {
  const { currentMarket, language } = config;
  const res = await apiPost<IAccountStatusResult>(
    `user/account/status?language=${language}&countryCode=${currentMarket?.countryCode}&purchaseType=${props.purchaseType}`,
    {
      email: email.value,
    }
  );

  if (res?.hasUserAccount) {
    hasUserAccount.value = res?.hasUserAccount;
  }
  if (lastError.value) {
    emailError.value = lastError.value;
  }
};

const toggleCreateAccount = async(checked: boolean) => {
  wantToCreateAccount.value = checked;
  await setCreateAccountStatus();
};

const toggleSubscribeToNewsLetter = (checked: boolean) => {
  subscribeToNewsLetter.value = checked;
  wantToSubscribeToNewsletter.value = checked;

  setNewsletterStatus();
};

const onSetBuyer = async() => {
  const { currentMarket, language } = config;

  if (!validateInput() || postalCodeError.value || emailError.value) {
    return;
  }

  loading.value = true;
  await apiPut(
    `checkout/buyer?language=${language}&countryCode=${currentMarket?.countryCode}&purchaseType=${props.purchaseType}`,
    {
      email: email.value,
      postalCode: postalCode.value,
      purchaseType: props.purchaseType,
    }
  );
  voyadoStore.setContactIdFromEmail(email.value);
  emit('set-newsletter-status', wantToSubscribeToNewsletter.value);
  emit('set-create-account-status', wantToCreateAccount.value);
  emit('on-confirmed', postalCode.value);

  if (
    (wantToSubscribeToNewsletter.value && wantToCreateAccount.value) ||
    hasUserAccount.value
  ) {
    emit('hide-checkboxes');
  }

  window.dataLayer?.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        products:
          cartItems.value?.map((cartItem) => cartItem.gtmProperties) ?? [],
        actionField: {
          step: 2,
          action: 'checkout',
        },
      },
      currencyCode: config.currentMarket?.currencyCode,
    },
  });

  if (lastError.value) {
    const message = lastError.value;
    $event('trigger-error-bar', {
      message,
    });
  }
  loading.value = false;
};

const validateInput = () => {
  let isValid = true;
  if (!email.value && !emailError.value) {
    isValid = false;
    emailError.value = $lang(
      'validationErrorResources',
      'emptyFormError'
    );
  }
  if (!postalCode.value) {
    isValid = false;
    postalCodeError.value = $lang(
      'validationErrorResources',
      'emptyFormError'
    );
  }

  return isValid;
};

const setCreateAccountStatus = async() => {
  await apiPut(
    `checkout/createaccountstatus?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
    {
      createAccount: wantToCreateAccount.value,
      purchaseType: props.purchaseType,
    }
  );
};

const setNewsletterStatus = async() => {
  await apiPut(
    `checkout/newsletterstatus?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
    {
      signUp: wantToSubscribeToNewsletter.value,
      purchaseType: props.purchaseType,
    }
  );
};

const onOpenPrivacyPolicyModal = () => {
  privacyPolicyModalIsOpen.value = true;
};

const onClosePrivacyPolicyModal = () => {
  privacyPolicyModalIsOpen.value = false;
  document.body.classList.remove('locked');
};

</script>
<style scoped>
.form-row {
  @apply flex border-t border-l border-r;
}
</style>
